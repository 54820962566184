body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Colors from: http://colorbrewer2.org/#type=sequential&scheme=OrRd&n=3 */

.review-1 {
  background-color: #e5f5e0;
}

.review-2 {
  background-color: #a1d99b;
}

.review-3 {
  background-color: #31a354;
  text-emphasis: bold;
}

.review--1 {
  background-color: #fee8c8;
}

.review--2 {
  background-color: #fdbb84;
}

.review--3 {
  background-color: #e34a33;
}
